/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Nathan's Portfolio",
  description:
    "A passionate student and developer who loves all things coding and AI and who is always willing to learn more to grow and succeed and thrives when work is challenging and engaging.",
  og: {
    title: "Nathan Kawamoto's Portfolio",
    type: "website",
    url: "http://nathankawamoto.com/",
  },
};

//Home Page
const greeting = {
  title: "Nathan Kawamoto",
  logo_name: "Nathan Kawamoto",
  nickname: "ForkBomb20",
  subTitle:
    "A passionate student and developer who loves all things coding and AI and who is always willing to learn more to grow and succeed and thrives when work is challenging and engaging.",
  resumeLink:
    "https://drive.google.com/file/d/1FW4cnJHHxQhuUX4CIuEVrLdHLaSX2n0S/view?usp=sharing",
  portfolio_repository: "https://github.com/forkbomb20/ForkBomb20.github.io",
  githubProfile: "https://github.com/forkbomb20",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/forkbomb20",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/nathan-kawamoto/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://youtube.com/c/DevSense19",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:njkawamoto@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "X-Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
  //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing machine learning models to identify nuances in patterns in the world around us",
        "⚡ Experience working with data driven ML models and education in basic architectures",
        "⚡ Dynamic modeling of real world systems, nature, using machine learning and genetic algorithms",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Scikit Learn",
          fontAwesomeClassname: "simple-icons:scikitlearn",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "JavaScript Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React",
        "⚡ Developing simulation based web-apps using P5.js",
        "⚡ Creating application backend in Node",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "mdi:nodejs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "P5.js",
          fontAwesomeClassname: "logos:p5js",
          style: {
            color: "#663399",
          },
        },
      ],
    },
    {
      title: "IOS Application Development",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing effective IOS applications using Swift & SwiftUI",
        "⚡ Incorporating machine learning concepts using CreateML",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Swift UI",
          fontAwesomeClassname: "logos:swift",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "iOS",
          fontAwesomeClassname: "logos:ios",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "CreateML",
          fontAwesomeClassname: "carbon:machine-learning-model",
          style: {
            color: "#FF2BC2",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "LeetCode",
    //   iconifyClassname: "simple-icons:leetcode",
    //   style: {
    //     color: "#F79F1B",
    //   },
    //   profileLink: "https://leetcode.com/layman_brother/",
    // },
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Michigan - Ann Arbor",
      subtitle: "Bachelors of Science, Engineering in Computer Science",
      logo_path: "umich.png",
      alt_name: "",
      duration: "2023 - Present",
      descriptions: [
        "⚡ Upper division student studying computer science and data science in the college of engineering",
        "⚡ Individual focus on machine learning and artificial intelligence",
        "⚡ 4.0 GPA and project lead for multiple data science and machine learning student organizations (Michigan Student AI Lab and Michigan Data Science Team)",
      ],
      website_link: "https://umich.edu/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Python",
      subtitle: "- Jose Portilla",
      logo_path: "python.png",
      certificate_link: "https://www.udemy.com/certificate/UC-ECYEIN9U/",
      alt_name: "Udemy",
      color_code: "#ffffff",
    },
    {
      title: "GFACT | Cyber Security",
      subtitle: "- GIAC",
      logo_path: "giac.png",
      certificate_link:
        "https://www.credly.com/badges/064fca67-5609-4a03-baab-1e2c30f8e548",
      alt_name: "GIAC",
      color_code: "#ffffff",
    },
    {
      title: "JavaScript",
      subtitle: "- Jonas Schmedtmann",
      logo_path: "JavaScript-logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-621d8637-9182-4557-93a3-0ee276951ddb/",
      alt_name: "Udemy",
      color_code: "#ffffff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have been involved in many computer science and STEM oriented organizations and have been practicing skills in customer service along side this",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Customer Service Representative",
          company: "Micro Center",
          company_url:
            "https://www.microcenter.com/site/content/about_microcenter.aspx",
          logo_path: "micro.jpg",
          duration: "June 2022 - January 2024",
          location: "Denver, CO",
          description:
            "This position involved standard retail register duties as well as providing technical support services to customers and sales talents for store merchandise and warranties. Top salesman at regional location.",
          color: "#0879bf",
        },
        {
          title: "Undergraduate Researcher",
          company: "Nuclear Engineering and Radiological Sciences — University of Michigan",
          company_url:
            "https://ners.engin.umich.edu/",
          logo_path: "umich.png",
          duration: "January 2024 - Present",
          location: "Ann Arbor, MI",
          description:
            "Provided data collection and analysis skills for research regarding the community centered design of nuclear energy technology in Ann Arbor and how similar techniques can be applied externally. Tasks included academic writing, collecting and analyzing community sentiment using data science techniques and NLP, and conducting interviews.",
          color: "#0879bf",
        },
        {
          title: "Undergraduate Researcher",
          company: "University of Michigan Transportation Research Institute",
          company_url:
            "https://www.umtri.umich.edu/",
          logo_path: "umich.png",
          duration: "May 2024 - Present",
          location: "Ann Arbor, MI",
          description:
            "Provided machine learning expertise for research regrading augmentation and improvement of popular Federated Learning techniques. Tasks included augmentation of existing code bases for optimized performance, including, but not limited to, improving algorithm accuracy and communication load, as well as algorithm analysis.",
          color: "#0879bf",
        },
        {
          title: "Instructor & Program Manager",
          company: "Inspirit AI",
          company_url:
            "https://www.inspiritai.com/",
          logo_path: "inspirit.png",
          duration: "May 2024 - Present",
          location: "Denver, CO",
          description:
            "Instructed middle school students in the fundamentals of artificial intelligence. This instruction included tutoring in Python, and guiding students through creating projects centered around understanding AI such as programming custom chatbots or toy neural networks. Additionally managed sessions for other instructors, this included managing virtual zoom rooms and managing student and instructor attendance.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Software Development Intern",
          company: "Graph Academy",
          company_url: "https://www.graph.academy/",
          logo_path: "GA Logo Dark.webp",
          duration: "May 2024 - Present",
          location: "Bay Area, CA",
          description:
            "Implementing backend features on private application, and performing database hardening and management",
          color: "#000000",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Chapter Head",
          company: "Steel City Codes",
          company_url: "https://www.steelcitycodes.org/",
          logo_path: "steel_city.png",
          duration: "January 2021 - May 2023",
          location: "Denver, CO",
          description:
            "Led a local chapter of the non-profit, Steel City Codes, in which I prepared weekly lessons for students to learn the basics of Python. This program served mainly to expose younger students to real coding work before middle or high school. Students gained a strong understanding of the basics of coding and Python.",
          color: "#4285F4",
        },
        {
          title: "Project Lead",
          company: "Michigan Student AI Lab",
          company_url: "https://msail.github.io/",
          logo_path: "msail.png",
          duration: "January 2024 - Present",
          location: "Ann Arbor, MI",
          description:
            "Led a semester long project wherein students would learn the basics of machine learning by coding a multi-layer perceptron from scratch. Students then used their created models analyze and predict on some public data using data science skills also taught.",
        },
        {
          title: "Project Lead",
          company: "Michigan Data Science Team",
          company_url: "https://mdst.club/",
          logo_path: "mdst.png",
          duration: "January 2024 - Present",
          location: "Ann Arbor, MI",
          description:
            "Led a team of students in creating a data science driven project utilizing core machine learning concepts to create an impactful deliverable project which extracted patterns and correlations from publicly available data.",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of a variety of the latest technological tools. I am most experienced in the creation of data science and machine learning projects to analyze and recreate real world systems and structures.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "head_shot.jpeg",
    description:
      "Please feel free reach out more directly via email, GitHub, or LinkedIn for any inquiries",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    locality: "Kanodar",
    country: "IN",
    region: "Gujarat",
    postalCode: "385520",
    streetAddress: "Ambavadi vas",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  // publicationsHeader,
  // publications,
  contactPageData,
};
